import type { GenericResponse } from "@thisisbud/internal-sdk";
import type { CreatedCustomInsight } from "../types/responses";
import type { CreatedCustomInsight as CreatedCustomInsightEntity } from "../types/entities";

export type ApiResponse = GenericResponse<CreatedCustomInsight>;

/**
 * Formats a GET Custom Insights API response.
 *
 * @param res - The response received from the custom insights endpoint
 * @returns A list of custom insights for the user
 * @public
 */
export function formatPostCustomInsightResponse(response: ApiResponse): CreatedCustomInsightEntity {
  const { data } = response;

  return {
    id: data.custom_insight_id,
  };
}
