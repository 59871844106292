import * as React from "react";

import { FormattedCurrency } from "@thisisbud/bui-react-formatted-currency";
import { useI18n } from "@thisisbud/i18n-react";

type Props = {
  amount: number;
  currency: string;
};

/**
 * Format a currency amount with an accessible alternative.
 *
 * @param props - The component props
 */
export default function CurrencyAmount(props: Props): React.ReactElement {
  const { amount, currency } = props;
  const i18n = useI18n();

  const formatAccessibleCurrency = React.useCallback(
    function (a: number, c: string): string {
      return i18n.formatCurrency(a, c, "accessible");
    },
    [i18n],
  );

  const formatCurrency = React.useCallback(
    function (a: number, c: string): string {
      return i18n.formatCurrency(a, c);
    },
    [i18n],
  );

  return (
    <FormattedCurrency
      amount={amount}
      currency={currency}
      formatAccessibleCurrency={formatAccessibleCurrency}
      formatCurrency={formatCurrency}
    />
  );
}
