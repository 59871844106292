import { useQuery } from "@tanstack/react-query";

import { getCategories } from "../../../../../../api/operations/get-categories";
import { requireAuth } from "../../../../../../../client/utils/auth";

import type { L1Category } from "../../../../../../api/types/entities";

type UseCategoriesResult = {
  categories: L1Category[];
  isLoading: boolean;
};

async function queryFn(): Promise<L1Category[]> {
  const auth = await requireAuth();
  return getCategories(auth);
}

export function useCategories(): UseCategoriesResult {
  const { data, isLoading } = useQuery({
    queryFn: queryFn,
    queryKey: ["categories"],
    refetchOnWindowFocus: false,
  });

  return { categories: data ?? [], isLoading: isLoading };
}
