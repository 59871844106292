import * as React from "react";

import { useI18n } from "@thisisbud/i18n-react";

import type { PasswordRequirment } from "../components/password-requirements/types";

/**
 * Use a set of password user input requirements.
 *
 * @returns An array of password requirements
 */
export function usePasswordRequirements(): PasswordRequirment[] {
  const i18n = useI18n();

  return React.useMemo(function () {
    return [
      {
        id: "required-length",
        label: i18n.t("password-requirements.required-length"),
        regex: /^.{8,}$/,
      },
      {
        id: "lowercase-letter",
        label: i18n.t("password-requirements.lowercase-letter"),
        regex: /\p{Ll}/u,
      },
      {
        id: "uppercase-letter",
        label: i18n.t("password-requirements.uppercase-letter"),
        regex: /\p{Lu}/u,
      },
      {
        id: "numerical-character",
        label: i18n.t("password-requirements.numerical-character"),
        regex: /\p{Nd}/u,
      },
      {
        id: "special-character",
        label: i18n.t("password-requirements.special-character"),
        regex: /\p{S}|\p{P}/u,
      },
    ];
  }, []);
}
