import React, { useCallback, useEffect, useId, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { Label } from "@thisisbud/gds-ui/label";
import { Multiselect } from "@thisisbud/gds-ui/multiselect";
import { useI18n } from "@thisisbud/i18n-react";

import type { MultiselectOption } from "@thisisbud/gds-ui/multiselect";
import type { L1Category } from "../../../api/types/entities";

type Option = { label: string; value: string };
const cspNonce = document.querySelector("html")?.attributes.getNamedItem("data-nonce")?.value;

type Props = {
  options: L1Category[];
  ruleKey: string;
};

function toOption({ displayName, name }: { displayName: string; name: string }): Option {
  return { label: displayName, value: name };
}

export function Categories({ ruleKey, options }: Props): React.ReactElement {
  const id = useId();
  const { control, setValue, watch, getValues } = useFormContext();
  const { t } = useI18n();

  // Map the categories into `Option`s.
  const mappedOptions = useMemo(() => {
    return options.map<MultiselectOption>((l1Catgegory) => ({
      ...toOption(l1Catgegory),
      options: l1Catgegory.subcategories.map(toOption),
    }));
  }, [options]);

  /*
   * We only allow selection of L2 categories (because we can't AND L1s) so the state
   * of the component is a bit different from the state of the field. To accomplish that,
   * there's a custom field for internal state. The value of this is watched and updates
   * the 'real' field on change.
   */
  const fieldKeyName = `${ruleKey}.value`;
  const customKeyName = `__categories__${ruleKey}.value`;

  // Get a default value if the form initially has a default (e.g. in edit mode)
  const defaultFieldValue = useMemo(() => {
    const fieldValue = getValues(fieldKeyName);
    if (Array.isArray(fieldValue)) {
      return (fieldValue as string[]).map((value) => ({ value }));
    }

    return [];
  }, [fieldKeyName, getValues]);

  const localStateValue: Option[] | undefined = watch(customKeyName);

  // Only L2 selections are allowed, so we need a lookup to check selections against
  const l2Lookups = useMemo(() => {
    return new Set(
      options.flatMap((option) => option.subcategories.map(toOption).map(({ value }) => value)),
    );
  }, [options]);

  // Update the local mapped values when the component state changes
  const selectedL2Values = useMemo(() => {
    return localStateValue?.filter(({ value }) => l2Lookups.has(value)) ?? [];
  }, [l2Lookups, localStateValue]);

  // Update the form field value when calculated selections change
  useEffect(() => {
    setValue(
      fieldKeyName,
      selectedL2Values.map(({ value }) => value),
    );
  }, [fieldKeyName, selectedL2Values, setValue]);

  const formatAriaRemoveLabel = useCallback(
    (option: Option) => {
      return t("bud-ql-query-builder.query.form.categories.remove-label", option);
    },
    [t],
  );

  const noOptionsMessage = useCallback(() => {
    return t("bud-ql-query-builder.query.form.categories.no-options");
  }, [t]);

  return (
    <div>
      <Controller
        control={control}
        defaultValue={defaultFieldValue}
        name={customKeyName}
        render={({ field }) => {
          const onChange = (newValue: Option[]): void => {
            field.onChange({ target: { field: field.name, value: newValue } });
          };

          return (
            <div>
              <Label label={t("bud-ql-query-builder.query.form.categories.label")} labelId={id} />
              <Multiselect
                closeMenuOnSelect={false}
                cspNonce={cspNonce}
                formatAriaRemoveLabel={formatAriaRemoveLabel}
                id={id}
                noOptionsMessage={noOptionsMessage}
                options={mappedOptions}
                placeholder={t("bud-ql-query-builder.query.form.categories.empty-option")}
                value={field.value}
                onChange={onChange}
              />
            </div>
          );
        }}
      />
    </div>
  );
}
