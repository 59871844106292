import { assertDefined } from "../type-guards";

import type { QueryOutput, QueryValue, Rule } from "./types";

const formatRuleValue = (value: QueryValue): string | number => {
  if (Array.isArray(value)) {
    if (!value.every(isNaN)) {
      // is an array of numbers
      return `${value.join(", ")}`;
    }

    return `("${value.filter(Boolean).join('", "')}")`;
  }

  if (typeof value === "string") {
    return `"${value}"`;
  }

  if (typeof value === "number") {
    return value.toString();
  }

  throw new Error("Unable to serialize Bud QL");
};

const formatQuery = (objRule: Rule, rules: Rule[]): string => {
  const { rule, value } = objRule;

  switch (rule) {
    case "category_l1":
    case "category_l2":
    case "credit_debit_indicator":
    case "currency": {
      const { operator } = objRule;
      assertDefined(operator, "Operator undefined");

      return `${rule} ${operator} ${formatRuleValue(value)}`;
    }
    case "amount": {
      const { operator } = objRule;
      assertDefined(operator, "Operator undefined");

      const hasCreditDebitIndicator = rules.some((r) => r.rule === "credit_debit_indicator");

      const amountRule = hasCreditDebitIndicator ? "ABS(amount)" : "amount";

      return `${amountRule} ${operator} ${formatRuleValue(value)}`;
    }
    case "date_time": {
      const { aggregator } = objRule;
      assertDefined(aggregator, "Aggregator undefined");

      return `${aggregator}(${rule}, ${formatRuleValue(value)})`;
    }
    case "tags": {
      const { operator } = objRule;
      assertDefined(operator, "Operator undefined");

      if (Array.isArray(value)) {
        return value.map((tag) => `${formatRuleValue(tag)} ${operator} ${rule}`).join(" AND ");
      }

      return `${formatRuleValue(value)} ${operator} ${rule}`;
    }
    default:
      throw new Error(`Invalid rule ${rule as string}`);
  }
};

const formatHaving = (rules?: Rule[]): string => {
  type PercentageOfIncomeRule = Extract<Rule, { rule: "percentage_income" }>;
  const percentageIncomeRule = rules?.find(
    (rule): rule is PercentageOfIncomeRule => rule.rule === "percentage_income",
  );
  if (!percentageIncomeRule || !rules) return "";

  const { operator, value } = percentageIncomeRule;

  const havingRules = rules.filter((rule) => ["currency", "date_time"].includes(rule.rule));
  const rulesStr = `${havingRules.map((objRule) => formatQuery(objRule, rules)).join(" AND ")} AND "income" IN tags`;

  // Calculate precision to deal with floating point weirdness
  const precision = value?.toString().replace(".", "").length;
  const valueStr = parseFloat((Number(value) / 100).toPrecision(precision));

  return ` HAVING ABS(SUM(amount)) ${operator.replace("-income", "")} SUM(SELECT amount FROM transactions WHERE ${rulesStr}) * ${valueStr}`;
};

const serializeRule = (rule: Rule, index: number, rules: Rule[]): string => {
  const formattedQuery = formatQuery(rule, rules);

  if (index === 0) return formattedQuery.trim();

  return ["AND", formattedQuery].join(" ").trim();
};

export const serializeQuery = (queryOutput: QueryOutput): string => {
  const { from, infer, rules } = queryOutput;
  const rulesExceptPercentageIncome = rules.filter((rule) => rule.rule !== "percentage_income");

  const rulesStr = rulesExceptPercentageIncome
    .map((rule, index) => serializeRule(rule, index, rulesExceptPercentageIncome))
    .join(" ");
  const havingStr = formatHaving(rules);

  return `INFER ${infer} FROM ${from} WHERE ${rulesStr}${havingStr}`;
};

export { serializeQuery as budQlSerializer };
