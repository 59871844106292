import * as z from "zod";

import { isValidUrlWithPath } from "../../../../../../utils/validation";
import { validateHeaderEntries } from "./utils";

const webhookCategory = z.enum(["open-banking", "payments"]);
const nonEmptyZodString = z
  .string()
  .trim()
  .min(1, { message: "projects.project.webhooks.create-webhook-dialog.field-is-required" });

const headers = z
  .array(
    z.object({
      key: z.string().optional(),
      value: z.string().optional(),
    }),
  )
  .superRefine((headerValues, ctx) => {
    headerValues.forEach(({ key, value }, index) => {
      const invalidParam = validateHeaderEntries({ key, value });
      if (invalidParam === false) return;

      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "projects.project.webhooks.create-webhook-dialog.field-is-required",
        path: [`${index}.${invalidParam}`],
      });
    });
  });

const optionalSettings = z.object({
  headers: headers.optional(),
  signingToken: z
    .string()
    .optional()
    .refine(
      (value) => {
        if (value === undefined || value === "") return true;
        if (value.length < 32) return false;

        return true;
      },
      {
        message: "projects.project.webhooks.create-webhook-dialog.signing-token-error",
      },
    ),
});

const webhook = z.object({
  category: webhookCategory,
  isEnabled: z.boolean(),
  name: nonEmptyZodString,
});

const webhooks = z.array(webhook).superRefine((selectedWebhooks, ctx) => {
  const isAtleastOneEnabled = selectedWebhooks.some((item) => item.isEnabled);

  if (!isAtleastOneEnabled) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "projects.project.webhooks.create-webhook-dialog.no-events-selected-error",
    });
  }
});

export const editWebhookSchema = z.object({
  optionalSettings: optionalSettings.optional(),
  webhookUrl: z.string().superRefine((url, ctx) => {
    if (!url.startsWith("https")) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "projects.project.update-subscription-dialog.form.webhook-url.validation.https",
      });
    } else if (!isValidUrlWithPath(url)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "projects.project.update-subscription-dialog.form.webhook-url.validation.invalid",
      });
    }
  }),
});

export const createWebhookSchema = editWebhookSchema.extend({
  webhooks,
});

export type EditWebhookSchemaForm = z.infer<typeof editWebhookSchema>;
export type CreateWebhookSchemaForm = z.infer<typeof createWebhookSchema>;
