import { Version, request } from "@thisisbud/internal-sdk";

import { Namespace } from "../constants";
import { formatGetCategoriesResponse } from "../formatters/categories";

import type { L1Category } from "../types/entities";
import type { GetCategoriesResponse } from "../types/responses";
import type { AuthDetails } from "../types/common";

/**
 * Get a list of categories.
 *
 * @param auth - The authentication details for the request
 * @returns A list of transaction categories
 *
 */
export async function getCategories(auth: AuthDetails): Promise<L1Category[]> {
  const response = await request<GetCategoriesResponse>(Namespace.resources, Version.v2, {
    auth: auth,
    method: "GET",
    path: "categories?format=map",
  });

  return formatGetCategoriesResponse(response);
}
