import * as React from "react";
import { useFormikContext } from "formik";

import { useI18n } from "@thisisbud/i18n-react";
import { Button } from "@thisisbud/bui-react-buttons";

import type { PasswordValidation } from "../password-requirements/types";

type Props = {
  validation: PasswordValidation;
};

export default function SubmitButton(props: Props): React.ReactElement {
  const i18n = useI18n();
  const { validation } = props;
  const { isSubmitting } = useFormikContext();

  const isDisabled = Object.values(validation).some(function (value) {
    return value !== true;
  });

  return (
    <Button
      align="right"
      busy={isSubmitting}
      compact={true}
      disabled={isDisabled}
      id="activate-account-submit-button"
      type="submit"
    >
      {i18n.t("activate-account.form.submit")}
    </Button>
  );
}
