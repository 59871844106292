import type { AppConfig, Currency } from "../../../types/config";
import type { FormRule, Query, Rule } from "../../utils/bud-ql/schema";

// eslint-disable-next-line @typescript-eslint/consistent-indexed-object-style
const getDefaultRules = (currency: Currency): Record<Rule["rule"], Rule> => {
  return {
    // @ts-expect-error operator can be undefined
    amount: {
      rule: "amount",
    },
    category_l2: {
      operator: "IN",
      rule: "category_l2",
    },
    credit_debit_indicator: {
      operator: "=",
      rule: "credit_debit_indicator",
    },
    currency: {
      operator: "=",
      rule: "currency",
      value: currency,
    },

    // @ts-expect-error value is a string
    date_time: {
      aggregator: "DATETIME_FROM",
      rule: "date_time",
      value: "0,0,30",
    },
    tags: {
      operator: "IN",
      rule: "tags",
    },
  };
};

const findOrUseDefault = (rules: Rule[], ruleType: Rule["rule"], defaultRule: Rule): FormRule => {
  const foundRule = rules.find((rule) => rule.rule === ruleType);

  if (foundRule) {
    // Format the rules to match the form types
    switch (foundRule.rule) {
      case "date_time": {
        return {
          ...foundRule,
          value: foundRule.value.toString(),
        };
      }

      case "category_l2": {
        return {
          ...foundRule,
          value: [foundRule.value ?? ""].flat(),
        };
      }

      default:
        return foundRule;
    }
  }

  return defaultRule;
};

const getInitialRules = (config: AppConfig, rules: Rule[]): FormRule[] => {
  const initialRules = [];

  const defaultRules = getDefaultRules(config.currency);

  // Add each rule in a static order
  initialRules.push(
    findOrUseDefault(rules, "credit_debit_indicator", defaultRules.credit_debit_indicator),
  );
  initialRules.push(findOrUseDefault(rules, "category_l2", defaultRules.category_l2));

  const percentageIncomeRule = rules.find((rule) => rule.rule === "percentage_income");

  if (percentageIncomeRule) {
    initialRules.push(percentageIncomeRule);
  } else {
    initialRules.push(findOrUseDefault(rules, "amount", defaultRules.amount));
  }

  initialRules.push(findOrUseDefault(rules, "tags", defaultRules.tags));
  initialRules.push(findOrUseDefault(rules, "date_time", defaultRules.date_time));
  initialRules.push(findOrUseDefault(rules, "currency", defaultRules.currency));

  return initialRules;
};

export const getInitialValues = (config: AppConfig, query?: Query): Query => {
  const rules = typeof query === "undefined" ? [] : query.rules;
  const initialRules = getInitialRules(config, rules as Rule[]);

  return {
    from: "transactions",
    infer: query?.infer ?? "",
    rules: initialRules,
  };
};
