import { useMutation, useQueryClient } from "@tanstack/react-query";

import { requireAuth } from "../../../../client/utils/auth";
import { createCustomInsight } from "../../operations/create-custom-insight";

import type { UseMutationResult } from "@tanstack/react-query";
import type { CreatedCustomInsight } from "../../types/entities";

type HookParameters = {
  environment: string;
  projectId: string;
};

type HookResponse = {
  create: UseMutationResult<CreatedCustomInsight, unknown, string>["mutate"];
} & Omit<UseMutationResult<CreatedCustomInsight, unknown, string>, "mutate">;

export function useCreateCustomInsight({ environment, projectId }: HookParameters): HookResponse {
  const queryClient = useQueryClient();
  const { mutate: create, ...params } = useMutation({
    mutationFn: async (query: string) => {
      const auth = await requireAuth();
      return createCustomInsight({
        auth,
        environment,
        projectId,
        query,
      });
    },
    // eslint-disable-next-line @typescript-eslint/promise-function-async
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["insights"],
        refetchType: "all",
      }),
  });

  return { create, ...params };
}
