import { assertDefined } from "../type-guards";

import type { LogicalOperator, QueryOutput, Rule } from "./types";

const translateComparisonOperator = (operator: string): string => {
  switch (operator) {
    case "=":
    case "=-income":
      return "equal to";
    case "<":
    case "<-income":
      return "below";
    case ">":
    case ">-income":
      return "above";
    default:
      throw new Error("Comparison operator not recongnized");
  }
};

const translateTimeframe = (timeframe: [number, number, number]): string => {
  const timeframeStr = timeframe.toString();

  switch (timeframeStr) {
    case "0,0,30":
      return "30 days";
    case "0,0,180":
      return "180 days";
    case "1,0,0":
      return "year";
    case "2,0,0":
      return "2 years";
    default:
      throw new Error(`Not compatible timeframe - ${timeframeStr}`);
  }
};

function listOrValueToString(value: string | string[], separator: string = "or"): string {
  if (Array.isArray(value) && value.length > 1) {
    const [lastItem] = value.slice(-1);
    const others = value.slice(0, value.length - 1);

    if (others.length === 1) {
      return `${others[0]} ${separator} ${lastItem}`;
    }

    return `${others.join(", ")}, ${separator} ${lastItem}`;
  }

  return value.toString();
}

const creditDebitMap: { [key: string]: string } = {
  credit: "incoming",
  debit: "outgoing",
};

function getOperatorText(
  ruleIndex: number,
  ruleKey: Rule["rule"],
  processedRules: Set<Rule["rule"]>,
  logicalOperator?: LogicalOperator,
): string {
  if (
    logicalOperator &&
    ruleIndex > 0 &&
    !["category_l1", "category_l2", "credit_debit_indicator"].includes(ruleKey)
  ) {
    switch (ruleKey) {
      case "date_time":
        if (processedRules.size > 1) {
          return ", ";
        }

        return "";

      case "amount":
        if (processedRules.has("amount") || processedRules.has("currency")) {
          return "and ";
        }

        return "with ";

      case "tags":
        return `${logicalOperator.toLowerCase()} `;

      default:
        return "";
    }
  }

  return "";
}

function budQlToText(queryOutput: QueryOutput): string {
  try {
    let result = "All transactions ";

    /*
     * Keep track of what rules have been already processed as the operator text varies depending
     * on what's gone before.
     */
    const processedRules = new Set<Rule["rule"]>();

    queryOutput.rules.forEach((rule, ruleIndex) => {
      const { rule: ruleKey, value } = rule;

      // Check for logical operator
      result += getOperatorText(ruleIndex, ruleKey, processedRules, "AND");

      switch (ruleKey) {
        case "credit_debit_indicator": {
          assertDefined(value, "Value undefined");

          result = result.replace(
            "All transactions",
            `All ${creditDebitMap[value.toString()]} transactions`,
          );
          break;
        }
        case "category_l1":
        case "category_l2": {
          assertDefined(value, "Value undefined");
          result += `on ${listOrValueToString(value)}`;
          break;
        }
        case "currency": {
          assertDefined(value, "Value undefined");
          result += `with currency ${value.toString()}`;
          break;
        }
        case "tags": {
          assertDefined(value, "Value undefined");
          result += `marked as ${listOrValueToString(value, "and")}`;
          break;
        }
        case "amount": {
          const { operator } = rule;
          assertDefined(value, "Value undefined");
          assertDefined(operator, "Operator undefined");

          if (ruleIndex === 0) {
            result += "having ";
          }

          const translation = translateComparisonOperator(operator);
          result += `amount ${translation} ${value.toString()}`;
          break;
        }
        case "date_time": {
          assertDefined(value, "Value undefined");
          const translation = translateTimeframe(value);
          result += `over the past ${translation}`;
          break;
        }
        case "percentage_income": {
          break;
        }
        default:
          throw new Error(`Rule not recognized ${ruleKey as string}`);
      }

      // Ensure spacing between rules
      if (!result.endsWith(" ")) {
        result += " ";
      }

      processedRules.add(ruleKey);
    });

    // Replace whitespace and spaces before commas
    result = result.replaceAll(/\s+,/g, ",").trim();

    const percentageIncomeRule = queryOutput.rules.find(
      (rule) => rule.rule === "percentage_income",
    );

    if (percentageIncomeRule) {
      const { operator, value } = percentageIncomeRule as Extract<
        Rule,
        { rule: "percentage_income" }
      >;
      const translatedOperator = translateComparisonOperator(operator);
      result += `, that amounted to ${translatedOperator} ${value as number}% of the income`;
    }

    return result;
  } catch (e) {
    throw new Error("Unable to translate to text");
  }
}

export { budQlToText };
