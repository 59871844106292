import * as React from "react";

import { Pill } from "@thisisbud/gds-ui/pill";
import { useI18n } from "@thisisbud/i18n-react";

type ReplacementTextWarningProps = { inputText?: string; outputText?: string };

function hasValue(value?: string): boolean {
  return typeof value !== "undefined" && value.length > 0;
}

export function ReplacementTextWarning({
  inputText,
  outputText,
}: ReplacementTextWarningProps): React.ReactElement | null {
  const i18n = useI18n();
  const text = React.useMemo(
    () =>
      i18n.t("bud-ql-query-builder.save.form.name.replacement", {
        value: outputText,
      }),
    [i18n, outputText],
  );

  if (hasValue(inputText) && hasValue(outputText) && inputText !== outputText) {
    return <Pill status="warning" text={text} />;
  }

  return null;
}
