import * as React from "react";

type Props = {
  title: string;
  children?: React.ReactNode;
  className?: string;
};

export function SinglePageHeader({ title, children, className = "" }: Props): React.ReactElement {
  return (
    <header className={`mt-5 flex items-center justify-between ${className}`}>
      <hgroup>
        <h1 className="text-2xl">{title}</h1>
      </hgroup>
      <hgroup className="flex gap-2">{children}</hgroup>
    </header>
  );
}
