import React, { useId, useState } from "react";

import { Switch } from "@thisisbud/gds-ui/switch";
import { Divider } from "@thisisbud/gds-ui/divider";
import { useI18n } from "@thisisbud/i18n-react";

import { Display } from "./display";

import type { QueryOutput } from "../../utils/bud-ql/types";

type Props = {
  query: QueryOutput;
};

export function QueryDisplayer(props: Props): React.ReactElement {
  const { query } = props;
  const [formatType, setFormatType] = useState<"text" | "query">("text");
  const i18n = useI18n();
  const displayId = useId();

  const toggleFormat = React.useCallback(() => {
    setFormatType((prevType) => (prevType === "text" ? "query" : "text"));
  }, []);

  return (
    <div className="w-auto rounded border border-surface-border">
      <Display formatType={formatType} id={displayId} query={query} />
      <div className="text-surface-border">
        <Divider />
      </div>
      <div className="px-4 py-2 text-surface-body">
        <Switch
          aria-controls={displayId}
          checked={formatType === "query"}
          label={i18n.t("projects.project.insights.format-switcher.label")}
          onCheckedChange={toggleFormat}
        />
      </div>
    </div>
  );
}
