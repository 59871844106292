import React from "react";

import type { PropsWithChildren } from "react";

type FieldsetProps = {
  counter: number | string;
  legend: string;
};

export function Fieldset({
  counter,
  legend,
  children,
}: PropsWithChildren<FieldsetProps>): JSX.Element {
  return (
    <fieldset className="grid grid-cols-[min-content_auto] grid-rows-none gap-4">
      <legend className="contents">
        <span className="inline-grid h-6 w-6 place-items-center rounded-full bg-surface-subtle text-base text-surface-dark">
          {counter}
        </span>
        <span className="text-base-strong">{legend}</span>
      </legend>
      <div className="col-start-2">{children}</div>
    </fieldset>
  );
}
