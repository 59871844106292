import type { GetCategoriesResponse, RawL2Category } from "../types/responses";
import type { L1Category, L2Category } from "../types/entities";

function mapNameToDisplayName(name: string): string {
  return name
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

function mapL2Category({ name }: RawL2Category): L2Category {
  return {
    displayName: mapNameToDisplayName(name),
    name: name,
  };
}

/**
 * Formats a GET Categories API response.
 *
 * @param res - The response received from the categories mapped endpoint
 * @returns A list of L1Category items
 * @public
 */
export function formatGetCategoriesResponse(response: GetCategoriesResponse): L1Category[] {
  const { data } = response;

  return data.map(({ name, subcategories = [] }) => ({
    displayName: mapNameToDisplayName(name),
    name: name,
    subcategories: subcategories.map(mapL2Category),
  }));
}
