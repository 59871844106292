import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

import { useI18n } from "@thisisbud/i18n-react";
import { Select } from "@thisisbud/gds-ui/select";

import { useBudQlContext } from "../context";

type CreditDebitIndicatorSelectProps = {
  ruleKey: string;
};

/*
 * Radix doesn't allow setting an Select.Item as empty string, so this value
 * is used as a substitute for that.
 */
const emptyValue = uuidv4();

export function CreditDebitIndicatorSelect({
  ruleKey,
}: CreditDebitIndicatorSelectProps): React.ReactElement {
  const { t } = useI18n();
  const { control } = useFormContext();
  const { transactionTypes } = useBudQlContext();

  return (
    <Controller
      control={control}
      name={`${ruleKey}.value`}
      render={({ field }) => {
        const onValueChange = (newValue: string): void => {
          const resolvedValue = newValue === emptyValue ? "" : newValue;
          field.onChange({
            target: { field: field.name, value: resolvedValue },
          });
        };

        const fieldValue = Boolean(field.value) ? field.value : emptyValue;

        return (
          <Select
            defaultValue={fieldValue}
            label={t("bud-ql-query-builder.query.form.transaction-type.label")}
            value={fieldValue}
            onValueChange={onValueChange}
          >
            <Select.Item value={emptyValue}>
              {t("bud-ql-query-builder.query.form.transaction-type.empty-option")}
            </Select.Item>
            {transactionTypes.map(({ value, labelKey }) => (
              <Select.Item key={value} value={value}>
                {t(labelKey)}
              </Select.Item>
            ))}
          </Select>
        );
      }}
    />
  );
}
