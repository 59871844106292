import React from "react";

import {
  doubleQuoteOrOtherRegex,
  doubleQuoteRegex,
  keywords,
  whitespaceRegex,
  wordOrWhitespaceRegex,
} from "./constants";

type Props = {
  query: string;
  insertLineBreaks?: boolean;
};

export function QueryFormatter({ query, insertLineBreaks = true }: Props): JSX.Element {
  const strings = query.match(wordOrWhitespaceRegex) ?? [];
  const formattedStrings: (string | React.ReactElement)[] = [];

  strings.forEach((string: string, index) => {
    // If whitespace return as-is
    if (whitespaceRegex.test(string)) {
      formattedStrings.push(string);
    } else if (keywords.includes(string.toUpperCase())) {
      // Make keyword blue and add a new line
      if (
        insertLineBreaks &&
        index !== 0 &&
        formattedStrings[formattedStrings.length - 1] !== <br />
      ) {
        formattedStrings.push(<br />);
      }
      formattedStrings.push(<span className="text-budql-keyword">{string}</span>);

      // Colorize "double quoted strings"
    } else if (doubleQuoteRegex.test(string)) {
      const elements = string
        .match(doubleQuoteOrOtherRegex)
        ?.filter(Boolean)
        .map((segment) => {
          if (doubleQuoteRegex.test(segment)) {
            return (
              <span className="text-budql-quotedstring" key={segment}>
                {segment}
              </span>
            );
          }

          // Otherwise, return the segment as-is
          return segment;
        });
      // eslint-disable-next-line react/jsx-no-useless-fragment
      formattedStrings.push(<React.Fragment>{elements}</React.Fragment>);
    } else {
      formattedStrings.push(string);
    }
  });

  return (
    <div className="font-mono">
      {formattedStrings.map((v, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={i}>{v}</React.Fragment>
      ))}
    </div>
  );
}
