import * as React from "react";

import type {
  PasswordRequirment,
  PasswordValidation,
} from "../components/password-requirements/types";

/**
 * Use a callback to validate a value that sets validation results.
 *
 * @returns A callback function, and the validation result
 */
export function usePasswordValidation(passwordRequirements: PasswordRequirment[]): {
  validation: PasswordValidation;
  validate(event: React.ChangeEvent<HTMLInputElement>): void;
} {
  const [validation, setValidation] = React.useState<PasswordValidation>({
    valid: false,
  });

  const validate = React.useCallback(
    function (event: React.ChangeEvent<HTMLInputElement>): void {
      const { value } = event.currentTarget;

      setValidation(
        passwordRequirements.reduce(function (acc, rule) {
          return {
            ...acc,
            [rule.id]: rule.regex.test(value),
          };
        }, {}),
      );
    },
    [passwordRequirements],
  );

  return {
    validate,
    validation,
  };
}
