import * as React from "react";

import { useCategories } from "./useCategories";

import type { BudQlOptions } from "../../../../../bud-ql-query-builder/types";

export function useBudQlOptions(): { options: BudQlOptions } {
  const { categories } = useCategories();

  const options: BudQlOptions = React.useMemo(
    () => ({
      categories: categories,
      dateRanges: ["0,0,30", "0,0,180", "1,0,0", "2,0,0"].map((value) => ({
        labelKey: `bud-ql-query-builder.query.form.date-range.options.${value}`,
        value: value,
      })),
      tagTypes: [
        "benefit",
        "debt-collection",
        "loan",
        "income",
        "pending",
        "regular-transaction",
        "subscription",
      ].map((value) => ({
        labelKey: `bud-ql-query-builder.query.form.tag-type.options.${value}`,
        value: value,
      })),
      transactionTypes: ["credit", "debit"].map((value) => ({
        labelKey: `bud-ql-query-builder.query.form.transaction-type.options.${value}`,
        value: value,
      })),
      transactionValues: ["transaction-value", "percentage-income"].flatMap((category) =>
        ["=", ">", "<"].map((operator) => ({
          labelKey: `bud-ql-query-builder.query.form.${category}.options.${operator}`,
          value: category === "percentage-income" ? `${operator}-income` : operator,
        })),
      ),
    }),
    [categories],
  );

  return { options };
}
