import * as React from "react";

import { InlineLink } from "@thisisbud/bui-react-inline-link";

type Props = {
  children: string;
  className?: string;
  href: string;
  onClick?(event: React.MouseEvent<HTMLAnchorElement>): void;
};

export default function ExternalInlineLink(props: Props): React.ReactElement {
  const { children, className, href, onClick } = props;

  return (
    <InlineLink
      className={className}
      href={href}
      rel="noreferrer"
      target="_blank"
      type="anchor"
      onClick={onClick}
    >
      {children}
    </InlineLink>
  );
}
