import React from "react";

import { useI18n } from "@thisisbud/i18n-react";

import { formatPayload } from "./utils";

type Props = {
  title?: string;
  payload?: { [key: string]: unknown } | string;
  testId?: string;
};

export function PayloadDisplayer(props: Props): React.ReactElement {
  const { t } = useI18n();
  const payload = props.payload ?? {};
  const title = props.title ?? t("projects.single-event.payload");
  const formattedPayload = formatPayload(payload);
  const rowNumbers = formattedPayload.split("\n").length;

  const rows = Array.from({ length: rowNumbers }, (_, index) => index + 1);

  return (
    <div
      className="flex max-w-[50%] flex-col rounded-lg border border-surface-strong bg-[#F3F4F6] max-md:max-w-full"
      data-testid={props.testId}
    >
      <header className="border-b border-surface-strong">
        <div className="px-3 py-4 font-bold">{title}</div>
      </header>
      <div className="flex w-max max-w-full px-3 py-4 font-mono">
        <div className="mr-5 flex flex-col text-surface-border">
          {rows.map((rowNumber) => (
            <div key={rowNumber}>{rowNumber}</div>
          ))}
        </div>
        <pre className="overflow-x-auto text-state-info-text">
          <code role="code">{formattedPayload}</code>
        </pre>
      </div>
    </div>
  );
}
