import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

import { useI18n } from "@thisisbud/i18n-react";
import { Input } from "@thisisbud/gds-ui/input";
import { Select } from "@thisisbud/gds-ui/select";

import { useBudQlContext } from "../context";
import { percentageOfIncomeRegExp } from "../../../utils/bud-ql/constants";
import { useConfig } from "../../../context/config";

type Props = {
  ruleKey: string;
};

/*
 * Radix doesn't allow setting an Select.Item as empty string, so this value
 * is used as a substitute for that.
 */
const emptyValue = uuidv4();

export function TransactionValue({ ruleKey }: Props): React.ReactElement {
  const { control, watch, setValue } = useFormContext();
  const { transactionValues } = useBudQlContext();
  const i18n = useI18n();
  const { currency } = useConfig();
  const currencySymbol = i18n.getCurrencySymbol(currency);

  const operatorKey = `${ruleKey}.operator`;
  const valueKey = `${ruleKey}.value`;

  const operatorValue = watch(operatorKey);
  const isIncome = percentageOfIncomeRegExp.test(operatorValue);
  const translationKey = isIncome ? "percentage-income" : "transaction-value";
  const prefixText = isIncome ? undefined : currencySymbol;
  const suffixText = isIncome ? "%" : undefined;

  return (
    <div className="flex flex-col gap-1">
      <Controller
        control={control}
        name={operatorKey}
        render={({ field }) => {
          const onValueChange = (newValue: string): void => {
            const resolvedValue = newValue === emptyValue ? "" : newValue;
            field.onChange({
              target: { field: field.name, value: resolvedValue },
            });

            // If this is cleared, also clear the value
            if (newValue === emptyValue) {
              setValue(valueKey, "");
            }
          };

          const fieldValue = Boolean(field.value) ? field.value : emptyValue;

          return (
            <Select
              label={i18n.t(`bud-ql-query-builder.query.form.${translationKey}.type-label`)}
              value={fieldValue}
              onValueChange={onValueChange}
            >
              <Select.Item value={emptyValue}>
                {i18n.t(`bud-ql-query-builder.query.form.${translationKey}.empty-option`)}
              </Select.Item>
              {transactionValues.map(({ value, labelKey }) => (
                <Select.Item key={value} value={value}>
                  {i18n.t(labelKey)}
                </Select.Item>
              ))}
            </Select>
          );
        }}
      />

      {Boolean(operatorValue) ? (
        <Controller
          control={control}
          name={valueKey}
          render={({ field, fieldState }) => {
            const { value, ...fieldProps } = field;

            return (
              <Input
                errorMessage={fieldState.error?.message}
                hideLabel={true}
                label={i18n.t(`bud-ql-query-builder.query.form.${translationKey}.value-label`)}
                prefixText={prefixText}
                suffixText={suffixText}
                type="number"
                value={value ?? ""}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...fieldProps}
              />
            );
          }}
          rules={{
            shouldUnregister: true,
          }}
        />
      ) : null}
    </div>
  );
}
