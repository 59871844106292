import { Version, request } from "@thisisbud/internal-sdk";

import { Namespace } from "../constants";
import { formatPostCustomInsightResponse } from "../formatters/create-custom-insight";

import type { ApiResponse } from "../formatters/create-custom-insight";
import type { AuthDetails } from "../types/common";
import type { CreatedCustomInsight } from "../types/entities";

export type Options = {
  auth: AuthDetails;
  environment: string;
  query: string;
  projectId: string;
};

/**
 * Create a custom insight for a specific project.
 *
 * @param options - The options for the request
 * @returns The id of the custom insight created
 *
 */
export const createCustomInsight = async ({
  auth,
  environment,
  query,
  projectId,
}: Options): Promise<CreatedCustomInsight> => {
  const response = await request<ApiResponse>(Namespace.insights, Version.v1, {
    auth: auth,
    body: { query: btoa(query) },
    headers: {
      "Content-Type": "application/json",
      "X-User-Env": environment,
    },
    method: "POST",
    path: `/custom/application/${encodeURIComponent(projectId)}/manage`,
    retries: 0, // let react-query handle retries
  });

  return formatPostCustomInsightResponse(response);
};
