import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { Select } from "@thisisbud/gds-ui/select";
import { useI18n } from "@thisisbud/i18n-react";

import { useBudQlContext } from "../context";

type CreditDebitIndicatorSelectProps = {
  ruleKey: string;
};

export function DateRangeSelect({ ruleKey }: CreditDebitIndicatorSelectProps): React.ReactElement {
  const { t } = useI18n();
  const { dateRanges } = useBudQlContext();
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={`${ruleKey}.value`}
      render={({ field }) => {
        const onValueChange = (newValue: string): void => {
          field.onChange({ target: { field: field.name, value: newValue } });
        };
        return (
          <Select
            label={t("bud-ql-query-builder.query.form.date-range.label")}
            placeholder={t("bud-ql-query-builder.query.form.date-range.empty-option")}
            value={field.value}
            onValueChange={onValueChange}
          >
            {dateRanges.map(({ value, labelKey }) => (
              <Select.Item key={value} value={value}>
                {t(labelKey)}
              </Select.Item>
            ))}
          </Select>
        );
      }}
    />
  );
}
