/* eslint-disable object-shorthand */

import type {
  MetadataResponse,
  SubscriptionResponse,
  WebhookEventResponse,
  WebhookRequestResponse,
} from "../api";

const emptyEventsResponse = {
  data: [],
};

const emptySubscriptionsResponse = {
  data: {
    subscriptions: [],
  },
};

const subscribableWebhooksResponse: { data: string[] } = {
  data: [
    "open_banking.connect.completed",
    "open_banking.refresh.completed",
    "open_banking.consent.reconfirmed",
    "open_banking.consent.revoked",
    "first_party_ingester.ingest.succeeded",
    "first_party_ingester.ingest.failed",
  ],
};

const subscribablePaymentWebhooksResponse: { data: string[] } = {
  data: [
    "payment.status.updated",
    "standing_order.status.updated",
    "scheduled_payment.status.updated",
  ],
};

const webhooksResponse: { data: SubscriptionResponse[] } = {
  data: [
    {
      org_id: "6d38ecbf-d7d1-46dd-954e-7a9d4090e015",
      app_id: "1234567890",
      event_type: "open_banking.consent.reconfirmed",
      endpoint: "https://test.com/test",
      signing_enabled: false,
      active: true,
      created_at: "2024-01-18T10:05:46Z",
      last_modified_at: "2024-01-18T10:05:46Z",
      last_modified_by: "07a0c182-3263-4e5a-8503-da397abb06ab",
    },
    {
      org_id: "6d38ecbf-d7d1-46dd-954e-7a9d4090e015",
      app_id: "1234567890",
      event_type: "open_banking.refresh.completed",
      endpoint: "https://test.com/test",
      signing_enabled: false,
      active: true,
      created_at: "2024-01-18T09:22:08Z",
      last_modified_at: "2024-01-18T09:22:08Z",
      last_modified_by: "07a0c182-3263-4e5a-8503-da397abb06ab",
    },
    {
      org_id: "6d38ecbf-d7d1-46dd-954e-7a9d4090e015",
      app_id: "1234567890",
      event_type: "open_banking.connect.completed",
      endpoint: "https://test.com/test",
      signing_enabled: false,
      active: true,
      created_at: "2024-01-18T09:19:10Z",
      last_modified_at: "2024-01-18T09:19:10Z",
      last_modified_by: "07a0c182-3263-4e5a-8503-da397abb06ab",
    },
  ],
};

const paymentsWebhooksResponse: { data: SubscriptionResponse[] } = {
  data: [
    {
      org_id: "6d38ecbf-d7d1-46dd-954e-7a9d4090e015",
      app_id: "1234567890",
      event_type: "payment.status.updated",
      endpoint: "https://test.com/test",
      signing_enabled: false,
      active: true,
      created_at: "2024-04-10T07:41:36Z",
      last_modified_at: "2024-04-10T07:41:36Z",
      last_modified_by: "07a0c182-3263-4e5a-8503-da397abb06ab",
    },
    {
      org_id: "6d38ecbf-d7d1-46dd-954e-7a9d4090e015",
      app_id: "1234567890",
      event_type: "standing_order.status.updated",
      endpoint: "https://test.com/test",
      signing_enabled: false,
      active: true,
      created_at: "2024-04-10T07:41:36Z",
      last_modified_at: "2024-04-10T07:41:36Z",
      last_modified_by: "07a0c182-3263-4e5a-8503-da397abb06ab",
    },
  ],
};

const fullWebhookResponse: { data: SubscriptionResponse[] } = {
  data: [
    {
      org_id: "6d38ecbf-d7d1-46dd-954e-7a9d4090e015",
      app_id: "12345678903",
      event_type: "open_banking.consent.revoked",
      endpoint: "https://test.com/test",
      signing_enabled: false,
      active: true,
      created_at: "2024-01-24T11:26:58Z",
      last_modified_at: "2024-01-24T11:26:58Z",
      last_modified_by: "07a0c182-3263-4e5a-8503-da397abb06ab",
    },
    {
      org_id: "6d38ecbf-d7d1-46dd-954e-7a9d4090e015",
      app_id: "12345678903",
      event_type: "first_party_ingester.ingest.failed",
      endpoint: "https://test.com/test",
      signing_enabled: false,
      active: true,
      created_at: "2024-01-24T11:26:58Z",
      last_modified_at: "2024-01-24T11:26:58Z",
      last_modified_by: "07a0c182-3263-4e5a-8503-da397abb06ab",
    },
    {
      org_id: "6d38ecbf-d7d1-46dd-954e-7a9d4090e015",
      app_id: "12345678903",
      event_type: "first_party_ingester.ingest.succeeded",
      endpoint: "https://test.com/test",
      signing_enabled: false,
      active: true,
      created_at: "2024-01-24T11:26:58Z",
      last_modified_at: "2024-01-24T11:26:58Z",
      last_modified_by: "07a0c182-3263-4e5a-8503-da397abb06ab",
    },
    {
      org_id: "6d38ecbf-d7d1-46dd-954e-7a9d4090e015",
      app_id: "12345678903",
      event_type: "open_banking.consent.reconfirmed",
      endpoint: "https://test.com/test",
      signing_enabled: false,
      active: false,
      created_at: "2024-01-18T10:05:46Z",
      last_modified_at: "2024-01-23T22:10:16Z",
      last_modified_by: "07a0c182-3263-4e5a-8503-da397abb06ab",
    },
    {
      org_id: "6d38ecbf-d7d1-46dd-954e-7a9d4090e015",
      app_id: "12345678903",
      event_type: "open_banking.connect.completed",
      endpoint: "https://test.com/test",
      endpoint_headers: {
        test1: "test1",
      },
      signing_enabled: true,
      active: false,
      created_at: "2024-01-18T09:19:10Z",
      last_modified_at: "2024-01-23T14:38:18Z",
      last_modified_by: "07a0c182-3263-4e5a-8503-da397abb06ab",
    },
    {
      org_id: "6d38ecbf-d7d1-46dd-954e-7a9d4090e015",
      app_id: "12345678903",
      event_type: "open_banking.refresh.completed",
      endpoint: "https://test.com/test",
      signing_enabled: false,
      active: true,
      created_at: "2024-01-18T09:22:08Z",
      last_modified_at: "2024-01-18T09:22:08Z",
      last_modified_by: "07a0c182-3263-4e5a-8503-da397abb06ab",
    },
  ],
};

const fullPaymentsWebhookResponse: { data: SubscriptionResponse[] } = {
  data: [
    {
      org_id: "6d38ecbf-d7d1-46dd-954e-7a9d4090e015",
      app_id: "5fb854fa-5c34-463d-97f8-6aa9edb8794c",
      event_type: "payment.status.updated",
      endpoint: "https://thisisbud.com/test",
      signing_enabled: false,
      active: true,
      created_at: "2024-04-10T07:41:36Z",
      last_modified_at: "2024-04-10T07:41:36Z",
      last_modified_by: "07a0c182-3263-4e5a-8503-da397abb06ab",
    },
    {
      org_id: "6d38ecbf-d7d1-46dd-954e-7a9d4090e015",
      app_id: "5fb854fa-5c34-463d-97f8-6aa9edb8794c",
      event_type: "standing_order.status.updated",
      endpoint: "https://thisisbud.com/test",
      signing_enabled: false,
      active: true,
      created_at: "2024-04-10T07:41:36Z",
      last_modified_at: "2024-04-10T07:41:36Z",
      last_modified_by: "07a0c182-3263-4e5a-8503-da397abb06ab",
    },
    {
      org_id: "6d38ecbf-d7d1-46dd-954e-7a9d4090e015",
      app_id: "5fb854fa-5c34-463d-97f8-6aa9edb8794c",
      event_type: "scheduled_payment.status.updated",
      endpoint: "https://thisisbud.com/test",
      signing_enabled: false,
      active: true,
      created_at: "2024-04-10T07:41:36Z",
      last_modified_at: "2024-04-10T07:41:36Z",
      last_modified_by: "07a0c182-3263-4e5a-8503-da397abb06ab",
    },
  ],
};
const eventResponse: { data: WebhookEventResponse[]; ["metadata"]: MetadataResponse } = {
  data: [
    {
      id: "event-1",
      org_id: "6d38ecbf-d7d1-46dd-954e-7a9d4090e015",
      app_id: "86b980ee-0ecc-47d5-a11d-f32870901338",
      request_id: "14d58d05-6863-92c4-a2ab-52f0c05a3a6e",
      customer_id: "test-customer",
      event_type: "open_banking.consent.reconfirmed",
      payload: {},
      metadata: {
        test: true,
        "x-request-sim": 0,
      },
      created_at: "2024-01-23T21:25:44Z",
      successfully_sent_at: null,
      status: "failed",
    },
    {
      id: "event-2",
      org_id: "6d38ecbf-d7d1-46dd-954e-7a9d4090e015",
      app_id: "86b980ee-0ecc-47d5-a11d-f32870901338",
      request_id: "41b29bcc-464e-9d8a-8ec6-3bce2247d4d7",
      customer_id: "test-customer",
      event_type: "open_banking.connect.completed",
      payload: {},
      metadata: {
        test: true,
        "x-request-sim": 0,
      },
      created_at: "2024-01-23T21:25:37Z",
      successfully_sent_at: "2024-01-23T21:25:37Z",
      status: "sent",
    },
    {
      id: "event-3",
      org_id: "6d38ecbf-d7d1-46dd-954e-7a9d4090e015",
      app_id: "86b980ee-0ecc-47d5-a11d-f32870901338",
      request_id: "34c94c95-dd8b-93b9-90c2-9f28a1e4b4ba",
      customer_id: "test-customer",
      event_type: "open_banking.consent.reconfirmed",
      payload: {
        test: true,
      },
      metadata: {
        "x-request-sim": 0,
      },
      created_at: "2024-01-18T15:06:31Z",
      successfully_sent_at: null,
      status: "created",
    },
    {
      id: "event-4",
      org_id: "6d38ecbf-d7d1-46dd-954e-7a9d4090e015",
      app_id: "86b980ee-0ecc-47d5-a11d-f32870901338",
      request_id: "34c94c95-dd8b-93b9-90c2-9f28a1e4b4ba",
      customer_id: "test-customer",
      event_type: "open_banking.consent.reconfirmed",
      payload: {},
      metadata: {
        "x-request-sim": 0,
      },
      created_at: "2024-01-18T15:06:31Z",
      successfully_sent_at: null,
      status: "retrying",
    },
    {
      id: "event-5",
      org_id: "6d38ecbf-d7d1-46dd-954e-7a9d4090e015",
      app_id: "86b980ee-0ecc-47d5-a11d-f32870901338",
      request_id: "34c94c95-dd8b-93b9-90c2-9f28a1e4b4ba",
      customer_id: "test-customer",
      event_type: "open_banking.consent.reconfirmed",
      payload: {},
      metadata: null,
      created_at: "2024-01-18T15:06:31Z",
      successfully_sent_at: null,
    },
  ],
  metadata: {
    count: 5,
    next_page_token: "",
  },
};

const paymentsEventResponse: { data: WebhookEventResponse[]; ["metadata"]: MetadataResponse } = {
  data: [
    {
      id: "payment-event-1",
      org_id: "6d38ecbf-d7d1-46dd-954e-7a9d4090e015",
      app_id: "5fb854fa-5c34-463d-97f8-6aa9edb8794c",
      request_id: "f03c6139-09dd-91aa-b663-0e823580432a",
      customer_id: "test-customer",
      event_type: "scheduled_payment.status.updated",
      status: "failed",
      payload: {},
      metadata: {
        test: true,
        "x-request-sim": 2,
      },
      created_at: "2024-04-10T07:42:56Z",
      successfully_sent_at: null,
    },
    {
      id: "payment-event-2",
      org_id: "6d38ecbf-d7d1-46dd-954e-7a9d4090e015",
      app_id: "5fb854fa-5c34-463d-97f8-6aa9edb8794c",
      request_id: "8547a19b-3954-98b8-a8d9-10e4618a8941",
      customer_id: "test-customer",
      event_type: "scheduled_payment.status.updated",
      status: "failed",
      payload: {},
      metadata: {
        test: true,
        "x-request-sim": 2,
      },
      created_at: "2024-01-18T15:06:31Z",
      successfully_sent_at: null,
    },
    {
      id: "payment-event-3",
      org_id: "6d38ecbf-d7d1-46dd-954e-7a9d4090e015",
      app_id: "5fb854fa-5c34-463d-97f8-6aa9edb8794c",
      request_id: "26ef3a7f-030b-9e90-b799-e4c45f5dda10",
      customer_id: "test-customer",
      event_type: "payment.status.updated",
      status: "failed",
      payload: {},
      metadata: {
        test: true,
        "x-request-sim": 2,
      },
      created_at: "2024-04-10T07:42:49Z",
      successfully_sent_at: null,
    },
    {
      id: "payment-event-4",
      org_id: "6d38ecbf-d7d1-46dd-954e-7a9d4090e015",
      app_id: "5fb854fa-5c34-463d-97f8-6aa9edb8794c",
      request_id: "336ca39a-c87d-9f25-85b6-a89b1fdf971d",
      customer_id: "",
      event_type: "payment.status.updated",
      status: "failed",
      payload: {},
      metadata: {
        test: true,
        "x-request-sim": 2,
      },
      created_at: "2024-04-10T07:42:49Z",
      successfully_sent_at: null,
    },
    {
      id: "payment-event-5",
      org_id: "6d38ecbf-d7d1-46dd-954e-7a9d4090e015",
      app_id: "5fb854fa-5c34-463d-97f8-6aa9edb8794c",
      request_id: "ea012e21-ab45-9094-a868-ff99199fcd83",
      customer_id: "",
      event_type: "payment.status.updated",
      status: "failed",
      payload: {},
      metadata: {
        test: true,
        "x-request-sim": 2,
      },
      created_at: "2024-04-10T07:42:48Z",
      successfully_sent_at: null,
    },
  ],
  metadata: {
    count: 5,
    next_page_token: "",
  },
};

const emptyDataResponse = {
  data: [],
};

const singleRequestResponse: { data: WebhookRequestResponse } = {
  data: {
    id: "request-1",
    event_id: "event-3",
    org_id: "6d38ecbf-d7d1-46dd-954e-7a9d4090e015",
    app_id: "1234567890",
    event_type: "open_banking.consent.reconfirmed",
    attempted_at: "2024-01-18T15:06:32Z",
    requested_url: "https://test.com/test",
    response_headers: undefined,
    response_code: 200,
    response_body: { event_details: "test" },
    request_timed_out: false,
    latency: "166ms",
    signed: false,
    encrypted: false,
    status: "succeeded",
  },
};

const requestsResponse: { data: WebhookRequestResponse[] } = {
  data: [
    {
      id: "request-1",
      event_id: "event-3",
      org_id: "6d38ecbf-d7d1-46dd-954e-7a9d4090e015",
      app_id: "1234567890",
      event_type: "open_banking.consent.reconfirmed",
      attempted_at: "2024-01-18T15:06:32Z",
      requested_url: "https://test.com/test",
      response_headers: undefined,
      response_code: 200,
      response_body: { event_details: "test" },
      request_timed_out: false,
      latency: "166ms",
      signed: false,
      encrypted: false,
      status: "succeeded",
    },
  ],
};

const paymentRequestsResponse: { data: WebhookRequestResponse[] } = {
  data: [
    {
      id: "payment-request-1",
      event_id: "payment-event-2",
      org_id: "6d38ecbf-d7d1-46dd-954e-7a9d4090e015",
      app_id: "1234567890",
      event_type: "scheduled_payment.status.updated",
      attempted_at: "2024-04-16T13:06:54Z",
      requested_url: "https://thisisbud.com/test",
      response_code: 200,
      response_body: "",
      request_timed_out: false,
      latency: "649ms",
      signed: false,
      encrypted: false,
      status: "succeeded",
    },
  ],
};

const singlePaymentRequestsResponse: { data: WebhookRequestResponse } = {
  data: {
    id: "payment-request-1",
    event_id: "payment-event-2",
    org_id: "6d38ecbf-d7d1-46dd-954e-7a9d4090e015",
    app_id: "1234567890",
    event_type: "scheduled_payment.status.updated",
    attempted_at: "2024-04-16T13:06:54Z",
    requested_url: "https://thisisbud.com/test",
    response_code: 200,
    response_body: "",
    request_timed_out: false,
    latency: "649ms",
    signed: false,
    encrypted: false,
    status: "succeeded",
  },
};

export const singleWebhookResponse: { data: SubscriptionResponse[] } = {
  data: [
    {
      org_id: "12345",
      app_id: "1234567890",
      event_type: "open_banking.consent.reconfirmed",
      endpoint: "https://test.com/test",
      endpoint_headers: {
        headers1: "headers1",
        headers2: "headers2",
        headers3: "headers3",
        headers4: "headers4",
      },
      signing_enabled: true,
      active: true,
      created_at: "2024-01-18T10:05:46Z",
      last_modified_at: "2024-01-18T10:05:46Z",
      last_modified_by: "07a0c182-3263-4e5a-8503-da397abb06ab",
    },
  ],
};

export const webhookTestIds = {
  eventPayload: "event-payload",
  responseBody: "response-body",
};

export const editWebhookBody = {
  endpoint: "https://test.com/test123",
  event_type: "open_banking.consent.reconfirmed",
  endpoint_headers: {
    headers1: "headers1",
    headers2: "headers2",
    headers3: "headers3",
  },
};

export const webhookResponses = {
  events: { emptyEventsResponse, eventResponse, paymentsEventResponse },
  subscriptions: { emptySubscriptionsResponse },
  webhooks: {
    subscribableWebhooksResponse,
    subscribablePaymentWebhooksResponse,
    webhooksResponse,
    paymentsWebhooksResponse,
    fullWebhookResponse,
    fullPaymentsWebhookResponse,
    singleWebhookResponse,
  },
  requests: {
    requestsResponse,
    singleRequestResponse,
    paymentRequestsResponse,
    singlePaymentRequestsResponse,
  },
  emptyDataResponse,
};

export const webhookRequestBodies = {
  editWebhookBody,
};
