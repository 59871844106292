/**
 * Formats the payload object or string into a nicely indented JSON string.
 * If the payload is already a string, it attempts to parse it as JSON and format the parsed object.
 * If parsing fails, it returns the original payload string.
 *
 * @param payload - The payload object or string to be formatted.
 * @returns The formatted JSON string representation of the payload.
 */
export const formatPayload = (payload?: { [key: string]: unknown } | string): string => {
  if (typeof payload === "string") {
    try {
      const formattedPayload = JSON.parse(payload);
      return JSON.stringify(formattedPayload, undefined, 2);
    } catch {
      return payload;
    }
  }
  return JSON.stringify(payload, undefined, 2);
};
