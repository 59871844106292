import * as React from "react";
import styled from "styled-components";
import classnames from "classnames";
import { rem } from "polished";

type Props = React.PropsWithChildren<{
  busy?: boolean;
  className?: string;
  describedBy?: string;
  liveRegion?: boolean;
}>;

const outerAppScreenClassName = "outer-app-screen";
const outerAppScreenContentClassName = `${outerAppScreenClassName}__content`;

const StyledRoot = styled.main`
  display: flex;

  .${outerAppScreenContentClassName} {
    width: 100%;
    max-width: ${rem(630)};
    margin: 0 auto;
  }
`;

export default function OuterAppScreen(props: Props): React.ReactElement {
  const { busy = false, children, className, describedBy, liveRegion = true } = props;

  return (
    <StyledRoot
      aria-busy={busy ? "true" : "false"}
      aria-describedby={describedBy}
      aria-live={liveRegion ? "assertive" : "off"}
      className={classnames(outerAppScreenClassName, className)}
    >
      <div className={outerAppScreenContentClassName}>{children}</div>
    </StyledRoot>
  );
}
