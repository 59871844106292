// TODO: this component is yet to be finalised. Move to bui once confirmed

import * as React from "react";
import classNames from "classnames";
import styled from "styled-components";

import { selectFromTheme } from "@thisisbud/sc-utils";
import { H4, h4ClassName } from "@thisisbud/bui-react-typography";

type IconComponentProps = {
  className?: string;
  label?: string;
};

type Props = React.PropsWithChildren<{
  className?: string;
  title: React.ReactNode;
  IconComponent: React.ComponentType<IconComponentProps>;
}>;

export const iconCardClassName = "icon-card";
const iconCardHeaderClassName = `${iconCardClassName}__header`;
const iconCardBodyClassName = `${iconCardClassName}__body`;
const iconCardIconContainerClassName = `${iconCardClassName}__icon-container`;

const StyledSection = styled.section`
  border-color: ${selectFromTheme("colorBorderIconCardDefault")};
  border-style: solid;
  border-width: ${selectFromTheme("borderWidthIconCardDefault")};
  border-radius: ${selectFromTheme("borderRadiusIconCardDefault")};
  padding: ${selectFromTheme("spacingLargeDefault")};

  & + & {
    margin-top: ${selectFromTheme("spacingSmallDefault")};
  }

  .${iconCardHeaderClassName} {
    .${iconCardIconContainerClassName} + .${h4ClassName} {
      margin-top: ${selectFromTheme("spacingXXSmallDefault")};
    }

    .${h4ClassName} {
      font-weight: ${selectFromTheme("fontWeightIconCardHeaderDefault")};
    }
  }

  .${iconCardBodyClassName} {
    margin-top: ${selectFromTheme("spacingXXXSmallDefault")};
  }

  .${iconCardIconContainerClassName} {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${selectFromTheme("spacingXLargeDefault")};
    height: ${selectFromTheme("spacingXLargeDefault")};
    border-radius: ${selectFromTheme("borderRadiusIconCardIconContainerDefault")};
    background-color: ${selectFromTheme("colorBackgroundIconCardIconContainerDefault")};
    color: ${selectFromTheme("colorTextPrimaryAccent")};
  }
`;

export default function IconCard(props: Props): React.ReactElement {
  const { children, className, title, IconComponent } = props;

  return (
    <StyledSection className={classNames(iconCardClassName, className)}>
      <header className={iconCardHeaderClassName}>
        <span className={iconCardIconContainerClassName}>
          <IconComponent />
        </span>
        <H4>{title}</H4>
      </header>
      <div className={iconCardBodyClassName}>{children}</div>
    </StyledSection>
  );
}
