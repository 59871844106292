import { budQlParser, budQlToText } from "../../../../../utils/bud-ql";
import { querySchema } from "../../../../../utils/bud-ql/schema";

import type { AppConfig } from "../../../../../../types/config";
import type { Query, QueryOutput } from "../../../../../utils/bud-ql/types";

type RawParsedResult = {
  text: string;
  type: "raw";
};

type QueryParsedResult = {
  text: string;
  type: "parsed";
  query: Query;
  parsedQuery: QueryOutput;
};

type ParsingResult = RawParsedResult | QueryParsedResult;

/**
 * Parses a given query and converts it to a textual format.
 * Returns the parsed query and its textual representation if successful,
 * otherwise returns the raw query.
 *
 * @param query - The query string to be parsed and formatted.
 * @returns An object containing the text and type of the query, and the parsed query object if parsing is successful.
 *
 */
export const parseAndFormatQuery = (queryString: string, config: AppConfig): ParsingResult => {
  try {
    const query = budQlParser(queryString, config);
    const parsed = querySchema.safeParse(query);

    if (parsed.success) {
      const parsedQuery = parsed.data;
      const text = budQlToText(parsedQuery);
      const type = "parsed";

      return {
        parsedQuery,
        query,
        text,
        type,
      };
    }

    throw new Error("Invalid query");
  } catch (error) {
    return {
      text: queryString,
      type: "raw",
    };
  }
};
