import React from "react";

import { budQlSerializer, budQlToText } from "../../utils/bud-ql";
import { QueryFormatter } from "../query-formatter";
import { Expander } from "./expander";

import type { QueryOutput } from "../../utils/bud-ql/types";

type Props = {
  id: string;
  query: QueryOutput;
  formatType: "text" | "query";
};

export function Display(props: Props): React.ReactElement | null {
  const { query, formatType, id } = props;

  try {
    const querySyntax = budQlSerializer(query);
    const text = budQlToText(query);

    return (
      <div className="p-4" id={id}>
        <Expander visible={formatType === "text"}>
          <div aria-hidden={formatType !== "text"}>{text}</div>
        </Expander>

        <Expander visible={formatType === "query"}>
          <QueryFormatter query={querySyntax} />
        </Expander>
      </div>
    );
  } catch (e) {
    return null;
  }
}
