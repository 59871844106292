import { createContext } from "@thisisbud/react-utils";

import type { BudQlOptions } from "./types";

type BudQlOptionProps = {
  options: BudQlOptions;
};

const { Provider, useContext } = createContext<BudQlOptionProps>(undefined);

export const BudQlProvider = Provider;

export function useBudQlContext(): BudQlOptions {
  return useContext().options;
}
