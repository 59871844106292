/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";

import { useI18n } from "@thisisbud/i18n-react";
import { Divider } from "@thisisbud/gds-ui/divider";
import { Input } from "@thisisbud/gds-ui/input";
import { Button } from "@thisisbud/gds-ui/button";

import { BudQlProvider } from "./context";
import { Fieldset } from "../fieldset";
import { useResolver } from "./useResolver";
import { QueryDisplayer } from "../query-displayer";
import { defaultInsightName, querySchema } from "../../utils/bud-ql/schema";
import { ReplacementTextWarning } from "./elements/replacement-text-warning";
import { getInitialValues } from "./utils";
import BudQlQueryBuilderRule from "./rule";
import { percentageOfIncomeRegExp } from "../../utils/bud-ql/constants";
import { useConfig } from "../../context/config";

import type { Query } from "../../utils/bud-ql/schema";
import type { BudQlOptions } from "./types";

const transactionValueRulePosition = 2;

type Props = {
  options: BudQlOptions;
  isLoading: boolean;
  isSuccess: boolean;
  onSubmit(query: Query): void;
  query?: Query;
};

export default function BudQlQueryBuilder({
  options,
  query,
  onSubmit,
  isLoading,
  isSuccess,
}: Props): React.ReactElement {
  const i18n = useI18n();
  const config = useConfig();

  const resolver = useResolver();
  const methods = useForm<Query>({
    defaultValues: getInitialValues(config, query),
    resolver: resolver,
  });

  const { fields, remove, insert } = useFieldArray({
    control: methods.control,
    name: "rules",
  });

  const rawWatchedValues = methods.watch();
  const resolvedQuery = React.useMemo(() => {
    const result = querySchema.safeParse(rawWatchedValues);
    if (result.success) {
      return result.data;
    }

    return null;
  }, [rawWatchedValues]);

  const replacementTextValue =
    Boolean(resolvedQuery?.infer) && resolvedQuery?.infer !== defaultInsightName
      ? resolvedQuery?.infer
      : "";

  const operatorValue = methods.watch(`rules.${transactionValueRulePosition}.operator`);
  const valueWatched = methods.watch(`rules.${transactionValueRulePosition}.value`);

  React.useEffect(() => {
    if (typeof operatorValue !== "undefined") {
      const isIncome = percentageOfIncomeRegExp.test(operatorValue);
      if (isIncome) {
        remove(2);
        insert(2, { operator: operatorValue, rule: "percentage_income", value: valueWatched });
      } else {
        remove(2);
        insert(2, { operator: operatorValue, rule: "amount", value: valueWatched });
      }
    }
  }, [insert, operatorValue, valueWatched, remove]);

  return (
    <BudQlProvider options={options}>
      <FormProvider {...methods}>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form noValidate={true} onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="mb-6">
            <Fieldset counter={1} legend={i18n.t("bud-ql-query-builder.query.title")}>
              <div className="flex flex-col gap-y-6">
                {fields.map((field, ruleIndex) => {
                  return <BudQlQueryBuilderRule key={field.id} ruleIndex={ruleIndex} />;
                })}
              </div>
            </Fieldset>
          </div>

          <div className="mb-6 mt-10">
            <Divider />
          </div>

          <Fieldset counter={2} legend={i18n.t("bud-ql-query-builder.save.title")}>
            <div className="flex flex-col">
              <div className="mb-2 flex flex-col gap-1">
                <Input
                  {...methods.register("infer")}
                  errorMessage={methods.formState.errors.infer?.message}
                  hint={i18n.t("bud-ql-query-builder.save.form.name.hint")}
                  label={i18n.t("bud-ql-query-builder.save.form.name.label")}
                  placeholder={i18n.t("bud-ql-query-builder.save.form.name.placeholder")}
                />

                <ReplacementTextWarning
                  inputText={rawWatchedValues.infer}
                  outputText={replacementTextValue}
                />
              </div>

              {resolvedQuery === null ? null : <QueryDisplayer query={resolvedQuery} />}

              <div className="mb-24 mt-8">
                <Button
                  busy={isLoading || isSuccess}
                  busyLabel={i18n.t("common.loading")}
                  type="submit"
                >
                  {i18n.t("bud-ql-query-builder.save.form.submit")}
                </Button>
              </div>
            </div>
          </Fieldset>
        </form>
      </FormProvider>
    </BudQlProvider>
  );
}
