import * as React from "react";
import { useFormContext } from "react-hook-form";

import { useBudQlContext } from "./context";
import {
  Categories,
  CreditDebitIndicatorSelect,
  DateRangeSelect,
  TagSelect,
  TransactionValue,
} from "./elements";

import type { Rule } from "../../utils/bud-ql/types";

type Props = {
  ruleIndex: number;
};

export default function BudQlQueryBuilderRule({ ruleIndex }: Props): React.ReactElement | null {
  const { getValues } = useFormContext();
  const { categories } = useBudQlContext();

  const ruleKey = `rules.${ruleIndex}`;
  const rule = getValues(ruleKey) as Rule;

  if (rule.rule === "category_l1" || rule.rule === "category_l2") {
    return <Categories options={categories} ruleKey={ruleKey} />;
  }

  if (rule.rule === "credit_debit_indicator") {
    return <CreditDebitIndicatorSelect ruleKey={ruleKey} />;
  }

  if (rule.rule === "tags") {
    return <TagSelect ruleKey={ruleKey} />;
  }

  if (rule.rule === "amount" || rule.rule === "percentage_income") {
    return <TransactionValue ruleKey={ruleKey} />;
  }

  if (rule.aggregator === "DATETIME_FROM") {
    return <DateRangeSelect ruleKey={ruleKey} />;
  }

  return null;
}
