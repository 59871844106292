export const queryRegExp =
  /^INFER (?<infer>.+?) FROM (?<from>.+?)(?:\sWHERE\s(?<where>.+?))?(?:\sHAVING\s(?<having>.+?))?$/;

export const selectRegExp = /^SELECT (?<select>.+?) FROM (?<from>.+?)(?:\sWHERE\s(?<where>.+?))?$/;

export const aggregatorGroupRegExp =
  /(?<aggregator>DATETIME_FROM)\((?<field>[^,]+),?\s*(?<dateValue>.*)\)/;

export const membershipRegExp =
  /^((?<ruleLhs>\w+)|"(?<valueLhs>.+)")\s+(?<operator>IN)\s+(\((?<valueRhs>.+)\)|(?<ruleRhs>\w+))$/;

export const ruleRegExp =
  /(?<rule>\w+)\s+(?<operator>[<=>]+)\s+?((?<numberValue>\d+(\.\d+)?)|"(?<stringValue>.+)")/;

export const amountRegExp = /(?:ABS\()?amount\)?\s*(?<operator>[><=]+)\s*(?<numberValue>[\d.]+)/;

export const doubleQuotesRegExp = /"/g;

export const havingRegExp =
  /(?<aggregateFn>ABS\(SUM\(amount\)\))\s*(?<operator>>|<|=)\s*(?<comparisonExpression>SUM\(SELECT.*?\)\s*\*\s*\d*\.?\d*)/;

export const comparisonExpressionRegExp =
  /SUM\((?<selectQuery>SELECT.*?)\)\s*\*\s*(?<value>\d*\.?\d+)/;

export const percentageOfIncomeRegExp = /-income/;
