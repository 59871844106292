import * as React from "react";
import classNames from "classnames";

import { SuccessNotificationIcon } from "@thisisbud/bui-react-notification-icons";

import {
  dotClassName,
  passwordRequirementsItemClassName,
  passwordRequirementsItemLabelClassName,
  passwordRequirementsItemValidClassName,
} from "./constants";

type Props = React.PropsWithChildren<{
  label: string;
  valid?: boolean;
}>;

export default function PasswordRequirementItem(props: Props): React.ReactElement {
  const { label, valid } = props;

  return (
    <li
      className={classNames(passwordRequirementsItemClassName, {
        [passwordRequirementsItemValidClassName]: valid,
      })}
    >
      {valid === true ? <SuccessNotificationIcon /> : <span className={dotClassName} />}
      <span className={passwordRequirementsItemLabelClassName}>{label}</span>
    </li>
  );
}
