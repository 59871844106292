import React from "react";
import classnames from "classnames";

import type { PropsWithChildren } from "react";

type ExpanderProps = {
  visible?: boolean;
};

export function Expander({
  children,
  visible = false,
}: PropsWithChildren<ExpanderProps>): React.ReactElement {
  const expanderClassName = classnames(
    "grid grid-rows-[0fr] overflow-hidden transition-[grid-template-rows]",
    {
      "grid-rows-[1fr]": visible,
    },
  );

  return (
    <div aria-hidden={!visible} className={expanderClassName}>
      <div className="min-h-0">{children}</div>
    </div>
  );
}
