import React from "react";
import { useParams } from "react-router-dom";

import { useI18n } from "@thisisbud/i18n-react";
import { Button } from "@thisisbud/gds-ui/button";

import RouterLink from "../../../../../router-link";
import { createHref } from "../../../../../../router";
import { RouteName } from "../../../../../../router/constants";

export function CreateNewInsightBtn(): React.ReactElement {
  const params = useParams();
  const i18n = useI18n();

  return (
    <RouterLink
      as={Button}
      href={createHref({
        name: RouteName.projectInsightsCreate,
        params: {
          clientId: params.clientId,
        },
      })}
      id="create-insight-button"
    >
      {i18n.t("projects.project.insights.create-button")}
    </RouterLink>
  );
}
