import { createContext } from "@thisisbud/react-utils";

import type { ProviderMap } from "@thisisbud/internal-sdk";
import type { TargetEnvironment } from "../../../../types/environment";

type OpenBankingProviders = {
  type: Extract<TargetEnvironment, "sandbox" | "production">;
  providers: ProviderMap;
};

type OpenBankingProvidersProps = {
  readonly value: OpenBankingProviders[];
};

const { Provider, useContext, withContext } = createContext<OpenBankingProvidersProps>("value");

export const OpenBankingProvidersProvider = Provider;

/**
 * Use the current open banking providers value.
 *
 * @returns The current open banking providers
 */
export function useOpenBankingProviders(): OpenBankingProviders[] {
  return useContext().value;
}

export const withClientConfig = withContext;
