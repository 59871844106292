import {
  aggregatorGroupRegExp,
  amountRegExp,
  comparisonExpressionRegExp,
  doubleQuotesRegExp,
  havingRegExp,
  membershipRegExp,
  queryRegExp,
  ruleRegExp,
  selectRegExp,
} from "./constants";
import {
  amountRule,
  categoryRule,
  creditDebitRule,
  currencyRule,
  dateTimeRule,
  percentageIncomeRule,
  tagsRule,
} from "./schema";

import type { AppConfig } from "../../../types/config";
import type { Query, QueryValue, Rule } from "./types";

const parseValue = (valueStr: string | undefined): QueryValue => {
  if (typeof valueStr === "undefined") return;

  if (valueStr.includes(",")) {
    return valueStr.split(",").map((val) => {
      return isNaN(Number(val)) ? val.trim() : Number(val);
    });
  }

  return isNaN(Number(valueStr)) ? valueStr.trim() : Number(valueStr);
};

const parseRule = (ruleStr: string): Rule => {
  const aggregatorMatch = aggregatorGroupRegExp.exec(ruleStr)?.groups;

  if (aggregatorMatch) {
    const { aggregator } = aggregatorMatch;

    switch (aggregator) {
      case "DATETIME_FROM": {
        const { dateValue } = aggregatorMatch;

        return dateTimeRule.parse({
          aggregator: aggregator,
          rule: "date_time",
          value: parseValue(dateValue),
        });
      }
    }
  }

  const membershipMatch = membershipRegExp.exec(ruleStr);
  if (membershipMatch !== null) {
    const { ruleLhs, ruleRhs, operator, valueLhs, valueRhs } = membershipMatch.groups ?? {};
    const rule = ruleLhs || ruleRhs;
    const value = valueLhs || valueRhs;

    if (rule === "tags") {
      return tagsRule.parse({
        operator: operator,
        rule: rule,
        value: parseValue(value.replace(doubleQuotesRegExp, "")),
      });
    }

    return categoryRule.parse({
      operator: operator,
      rule: rule,
      value: parseValue(value.replace(doubleQuotesRegExp, "")),
    });
  }

  const amountResult = amountRegExp.exec(ruleStr)?.groups;

  if (typeof amountResult !== "undefined") {
    const { operator, numberValue } = amountResult;

    return amountRule.parse({
      operator: operator,
      rule: "amount",
      value: parseValue(numberValue),
    });
  }

  const { rule, operator, numberValue, stringValue } = ruleRegExp.exec(ruleStr)?.groups ?? {};

  if (rule) {
    const value = numberValue || stringValue;

    if (rule === "credit_debit_indicator") {
      return creditDebitRule.parse({
        operator: operator,
        rule: rule,
        value: parseValue(value),
      });
    }

    if (rule === "currency") {
      return currencyRule.parse({
        operator: operator,
        rule: rule,
        value: parseValue(value),
      });
    }

    if (rule === "category_l1" || rule === "category_l2") {
      return categoryRule.parse({
        operator: operator,
        rule: rule,
        value: parseValue(value),
      });
    }
  }

  throw new Error(`Invalid rule format: ${ruleStr}`);
};

const squashRules = (rules: Rule[]): Rule[] => {
  // Squash [rule1, rule2, rule2, rule3, rule2] into [rule1, rule2, rule3] while keeping order

  const squashed: Rule[] = [];

  rules.forEach((rule) => {
    const existingRule = squashed.find((r) => r.rule === rule.rule);

    if (existingRule && rule.rule === "tags") {
      if (Array.isArray(existingRule.value)) {
        // @ts-expect-error - I don't know how to fix this
        existingRule.value.push(rule.value);
      } else if (existingRule.value !== null) {
        // @ts-expect-error - I don't know how to fix this
        existingRule.value = [existingRule.value, rule.value];
      }
    } else {
      squashed.push(rule);
    }
  });

  return squashed;
};

const parseWhere = (where: string): Rule[] => {
  const rules: string[] = where.split(" AND ");
  const parsedRules: Rule[] = rules.map(parseRule);
  return squashRules(parsedRules);
};

export const parseSelectQuery = (str: string): Rule[] => {
  const selectMatch = selectRegExp.exec(str);
  if (!selectMatch) throw new Error(`Invalid SELECT query: ${str}`);

  const { where } = selectMatch.groups ?? {};

  const rules = where ? where.split(" AND ") : [];

  return rules.map(parseRule);
};

const parseHaving = (str: string): Rule[] => {
  const havingMatch = havingRegExp.exec(str);
  if (!havingMatch) {
    throw new Error(`Invalid having clause: ${str}`);
  }

  const { operator, comparisonExpression } = havingMatch.groups ?? {};

  const match = comparisonExpressionRegExp.exec(comparisonExpression);

  if (!match) {
    throw new Error(`Invalid comparison expression: ${comparisonExpression}`);
  }

  const { selectQuery, value: valueStr } = match.groups ?? {};
  const rules = parseSelectQuery(selectQuery);

  return [
    ...rules,
    percentageIncomeRule.parse({
      operator: `${operator}-income`,
      rule: "percentage_income",

      // Calculate precision to deal with floating point weirdness
      value: (Number(valueStr) * 100).toPrecision(
        Math.max(1, valueStr.split(".").at(1)?.length ?? 0),
      ),
    }),
  ];
};

const validateHavingRules = (parsedHaving: Rule[], config: AppConfig): void => {
  if (parsedHaving.length < 3 || parsedHaving.length > 5) {
    throw new Error("There should be between three and four having rules"); // parser adds a percentage_income rule
  }

  const tagsRules = parsedHaving.filter((rule) => rule.rule === "tags");
  if (tagsRules.length !== 1 || tagsRules[0].value !== "income") {
    throw new Error('There should be one tag rule with value "income"');
  }

  const currencyRules = parsedHaving.filter((rule) => rule.rule === "currency");
  const currency = config.currency;
  if (currencyRules.length !== 1 || currencyRules[0].value !== currency) {
    throw new Error(`There should be one currency rule with value "${currency}"`);
  }

  const dateTimeRules = parsedHaving.filter((rule) => rule.rule === "date_time");
  if (dateTimeRules.length > 1) {
    throw new Error("There should be one date_time rule");
  }
};

export const parseQuery = (str: string, config: AppConfig): Query => {
  const queryMatch = queryRegExp.exec(str);
  if (!queryMatch) throw new Error(`Invalid query: ${str}`);

  const { infer, where, having } = queryMatch.groups ?? {};

  const parsedWhere = where ? parseWhere(where) : [];
  const parsedHaving = having ? parseHaving(having) : undefined;

  if (parsedHaving) {
    validateHavingRules(parsedHaving, config);
  }

  const rules = [
    ...parsedWhere,
    ...(parsedHaving?.filter((rule) => rule.rule === "percentage_income") ?? []),
  ];

  return {
    from: "transactions",
    infer: infer,
    rules: rules,
  };
};
