import * as z from "zod";

function ruleHasValue(rule: Rule): boolean {
  const value = rule.value;
  if (typeof value === "number") {
    return !isNaN(value);
  }

  if (Array.isArray(value)) {
    return value.length > 0;
  }

  return Boolean(value);
}

const currency = z.enum(["GBP", "USD"]);
const aggregator = z.enum(["SUM", "DATETIME_FROM"]);
const comparisonOperator = z.enum(["=", "<", ">", "=-income", "<-income", ">-income"]);
export const logicalOperator = z.enum(["AND", "OR"]);
const membershipOperator = z.literal("IN");

export const queryValue = z.union([
  z.string(),
  z.number(),
  z.undefined(),
  z.null(),
  z.nan(),
  z.array(z.union([z.string(), z.number(), z.undefined()])),
]);

export const creditDebitRule = z.object({
  aggregator: z.never().optional(),

  // Allow the input to be optional/empty but forces output to be a real operator
  operator: z
    .string()
    .optional()
    .transform((s) => {
      const parsed = z.literal(comparisonOperator.Enum["="]).safeParse(s);
      if (parsed.success) {
        return parsed.data;
      }

      return comparisonOperator.Enum["="];
    }),
  rule: z.literal("credit_debit_indicator"),
  value: queryValue,
});

export const currencyRule = z.object({
  aggregator: z.never().optional(),
  operator: z.literal("="),
  rule: z.literal("currency"),
  value: currency,
});

export const categoryRule = z.object({
  aggregator: z.never().optional(),
  operator: z.union([z.literal("="), membershipOperator]),
  rule: z.enum(["category_l1", "category_l2"]),
  value: z.union([z.string(), z.array(z.string())]).optional(),
});

export const tagsRule = z.object({
  aggregator: z.never().optional(),
  operator: membershipOperator,
  rule: z.literal("tags"),
  value: z.union([z.string(), z.array(z.string())]).optional(),
});

const amountOperator = z
  .string()
  .optional()
  .transform((s) => {
    const parsed = comparisonOperator.safeParse(s);
    if (parsed.success) {
      return parsed.data;
    }

    return comparisonOperator.Enum["="];
  });

export const amountRule = z.object({
  aggregator: z.never().optional(),

  // Allow the input to be optional/empty but forces output to be a real operator
  operator: amountOperator,
  rule: z.literal("amount"),
  value: queryValue.transform((value) => {
    // Coerce the value to a number if it's a non-empty string
    if (typeof value === "string" && value !== "") {
      return Number(value);
    }

    return value;
  }),
});

const dateTimeValueType = z.tuple([z.number(), z.number(), z.number()]);
const dateTimeRuleValue = z.union([
  dateTimeValueType,
  z
    .string()

    // 0,0,30 etc.
    .regex(/^\d+,\d+,\d+$/)
    .transform((values) => dateTimeValueType.parse(values.split(",").map(Number))),
]);

export const dateTimeRule = z.object({
  aggregator: z.literal(aggregator.enum.DATETIME_FROM),
  rule: z.literal("date_time"),
  value: dateTimeRuleValue,
});

export const percentageIncomeRule = z.object({
  operator: amountOperator,
  rule: z.literal("percentage_income"),
  value: queryValue.transform((value) => {
    // Coerce the value to a number if it's a non-empty string
    if (typeof value === "string" && value !== "") {
      return Number(value);
    }

    return value;
  }),
});

const rule = z.discriminatedUnion("rule", [
  creditDebitRule,
  currencyRule,
  categoryRule,
  tagsRule,
  amountRule,
  dateTimeRule,
  percentageIncomeRule,
]);

const allowedCharacterSet = /a-zA-Z0-9_/;
const nameRule = /^[a-zA-Z_][a-zA-Z0-9_]*$/;
const startsWithNumber = /^[0-9_]/;
const specialCharacterReplacement = "_";
const specialCharacterReplacementPlaceholder = "^";
const onlyReplacementCharacters = new RegExp(`^${specialCharacterReplacement}+$`);
export const defaultInsightName = "my_new_query";

const transformInfer = (value: string): string => {
  // Return the default value if nothing has been entered
  if (!Boolean(value)) {
    return defaultInsightName;
  }

  // If the value matches the rules, return as-is
  if (nameRule.test(value)) {
    return value;
  }

  /*
   * Replace any invalid characters...
   * e.g. '_hello world_'
   */
  const replaced = value

    /*
     * ...first using a placeholder so as not to conflict with any instances of the placeholder
     * character already in the input...
     * .e.g. replacing '_hello world_' to become '_hello_world_' makes it difficult to know which '_'
     * characters were in the original string. So instead we get '_hello^world_'
     */
    .replace(
      new RegExp(`[^${allowedCharacterSet.source}]`, "g"),
      specialCharacterReplacementPlaceholder,
    )

    /*
     * ...then split and join on the replacement to get rid of multiple replacements in a row
     * e.g. '?-?-?' becomes '_' rather than '_____'
     */
    .split(specialCharacterReplacementPlaceholder)
    .filter(Boolean)
    .join(specialCharacterReplacementPlaceholder)

    /*
     * then replace the placeholder with the 'real' replacement
     * e.g. '_hello^world_' -> '_hello_world_'
     */
    .replaceAll(specialCharacterReplacementPlaceholder, specialCharacterReplacement);

  /*
   * If the resultant string is now _only_ replacement characters, return the default
   * e.g. we now have '_____'. If the user entered this originally, it would have been accepted and
   * returned at the first check. We'll only get to here if this output is due to value replacements.
   */
  if (onlyReplacementCharacters.test(replaced)) {
    return defaultInsightName;
  }

  // Test again with the replaced values and return it if it's now valid
  if (nameRule.test(replaced)) {
    return replaced;
  }

  // Check for the edge-case of a name starting with a number
  if (startsWithNumber.test(replaced)) {
    return `${specialCharacterReplacement}${replaced}`;
  }

  // Return the default if still invalid
  return defaultInsightName;
};

const rulesSchema = z
  .array(rule)

  // Checking if there's an amount rule and ensuring more than one rule
  .refine((rules) => (rules.some((r) => r.rule === "amount") ? rules.length > 1 : true))
  .refine(
    (rules) =>
      rules.filter((r) => r.rule === "percentage_income" || r.rule === "amount").length <= 1,
    {
      message: "There can only be one percentage_income rule or one amount rule",
    },
  )
  .refine(
    (rules) =>
      !(
        rules.some((r) => r.rule === "percentage_income") && rules.some((r) => r.rule === "amount")
      ),
    {
      message: "There cannot be both amount and percentage_income rules",
    },
  )

  // Drop any empty rules
  .transform((rules) => rules.filter(ruleHasValue))

  // Ensure there is at least one rule
  .refine((rules) => rules.length > 0, {
    message: "At least one rule is required",
  });

export const querySchema = z.object({
  from: z.literal("transactions"),
  infer: z.string().trim().transform(transformInfer),
  rules: rulesSchema,
});

export type Aggregator = z.infer<typeof aggregator>;
export type ComparisonOperator = z.infer<typeof comparisonOperator>;
export type LogicalOperator = z.infer<typeof logicalOperator>;
export type MembershipOperator = z.infer<typeof membershipOperator>;
export type QueryValue = z.infer<typeof queryValue>;
export type Rule = z.infer<typeof rule>;
export type FormRule = z.input<typeof rule>;
export type TagsRule = z.infer<typeof tagsRule>;

export type Query = z.input<typeof querySchema>;
export type QueryOutput = z.output<typeof querySchema>;
