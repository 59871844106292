import * as React from "react";

import { useI18n } from "@thisisbud/i18n-react";
import { Form } from "@thisisbud/bui-react-form";
import { InputGroup } from "@thisisbud/bui-react-input-group";
import { Input } from "@thisisbud/bui-react-input";

import { usePasswordRequirements } from "../../hooks/use-password-requirements";
import { usePasswordValidation } from "../../hooks/use-password-validation";
import PasswordRequirements from "../password-requirements";
import SubmitButton from "./submit-button";

type InitialFormValues = {
  [key: string]: any;
};

type Props = {
  className?: string;
  initialFormValues: InitialFormValues;
  onSubmit(values: InitialFormValues): Promise<void>;
};

export default function SetPasswordForm(props: Props): React.ReactElement {
  const { className, initialFormValues, onSubmit } = props;
  const i18n = useI18n();
  const passwordRequirements = usePasswordRequirements();
  const { validate, validation } = usePasswordValidation(passwordRequirements);

  return (
    <Form className={className} initialValues={initialFormValues} onSubmit={onSubmit}>
      <InputGroup label={i18n.t("activate-account.form.password.label")}>
        <Input
          describedBy="new-password-requirements"
          id="set-password-input"
          name="password"
          type="password"
          onChange={validate}
        />
      </InputGroup>
      <PasswordRequirements
        accessibleLabel={i18n.t("activate-account.form.password.requirements.accessible-label")}
        id="set-password-requirements"
        requirements={passwordRequirements}
        validation={validation}
      />
      <SubmitButton validation={validation} />
    </Form>
  );
}
