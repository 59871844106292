// TODO: move to bui-react as a standalone component

import * as React from "react";
import styled from "styled-components";
import { em } from "polished";

import { selectFromTheme } from "@thisisbud/sc-utils";
import { paragraphSmallCss } from "@thisisbud/bui-react-typography";
import { successNotificationIconClassName } from "@thisisbud/bui-react-notification-icons";
import { AccessibleContent } from "@thisisbud/bui-react-accessible-content";

import PasswordRequirementItem from "./item";
import {
  dotClassName,
  passwordRequirementsItemClassName,
  passwordRequirementsItemLabelClassName,
  passwordRequirementsItemValidClassName,
} from "./constants";

import type { PasswordRequirment, PasswordValidation } from "./types";

type Props = React.PropsWithChildren<{
  accessibleLabel: string;
  id: string;
  requirements: PasswordRequirment[];
  validation: PasswordValidation;
}>;

const StyledList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;

  .${passwordRequirementsItemClassName} {
    display: flex;
    align-items: center;
    list-style-type: none;
    box-sizing: border-box;
    background-color: ${selectFromTheme("colorBackgroundPasswordRequirementItemDefault")};
    padding: ${selectFromTheme("spacingXXXSmallDefault")};
    border-radius: ${selectFromTheme("widthPasswordRequirementDotDefault")};
    margin-left: ${selectFromTheme("spacingXXXSmallDefault")};
    margin-top: ${selectFromTheme("spacingXXXSmallDefault")};

    &:first-child {
      margin-left: 0;
    }

    .${dotClassName} {
      width: ${selectFromTheme("widthPasswordRequirementDotDefault")};
      height: ${selectFromTheme("widthPasswordRequirementDotDefault")};
      margin: ${selectFromTheme("marginPasswordRequirementDotDefault")};
      border-radius: ${selectFromTheme("widthPasswordRequirementDotDefault")};
      background-color: ${selectFromTheme("colorTextPasswordRequirementItemDefault")};
    }

    &.${passwordRequirementsItemValidClassName} {
      background-color: ${selectFromTheme("colorBackgroundPasswordRequirementItemSuccess")};

      .${successNotificationIconClassName} {
        svg {
          width: ${em(16)};
          height: ${em(16)};
        }
      }
    }

    .${passwordRequirementsItemLabelClassName} {
      ${paragraphSmallCss}
      color: ${selectFromTheme("colorTextPrimaryOff")};
      margin-left: ${selectFromTheme("spacingXXXXSmallDefault")};
    }
  }
`;

export default function PasswordRequirements(props: Props): React.ReactElement {
  const { accessibleLabel, id, requirements, validation } = props;

  return (
    <AccessibleContent accessibleAlternative={accessibleLabel} id={id}>
      <StyledList>
        {requirements.map(function (requirement) {
          return (
            <PasswordRequirementItem
              key={requirement.id}
              label={requirement.label}
              valid={validation[requirement.id]}
            />
          );
        })}
      </StyledList>
    </AccessibleContent>
  );
}
