export const doubleQuoteRegex = /"[^"]*"/;

export const doubleQuoteOrOtherRegex = /[^"]+|"[^"]+"/g;

export const wordOrWhitespaceRegex = /\S+|\s+/g;

export const whitespaceRegex = /\s+/;

export const keywords: string[] = [
  "INFER",
  "WHERE",
  "HAVING",
  "SUM",
  "FROM",
  "DATETIME_FROM",
  "AND",
  "OR",
];
