import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { useI18n } from "@thisisbud/i18n-react";

import { defaultInsightName, querySchema, queryValue } from "../../utils/bud-ql/schema";

import type { Query } from "../../utils/bud-ql/schema";
import type { Resolver } from "react-hook-form";
import type I18n from "@thisisbud/i18n";

const inferSchema = querySchema.pick({ infer: true });

const rule = z.object({
  operator: z.string().optional(),
  rule: z.string(),
  value: queryValue,
});

/*
 * As the validation rules for the form are a bit different from what's required in the language parser,
 * the schema here is a lightweight wrapper that runs before the schema is fully validated on submit.
 * For example, the amount rule needs to show a validation error when the comparison value is omitted,
 * but this is just removed in the full schema validation to ensure that the output BudQL is always valid.
 */

const getValidationSchema = (i18n: I18n) =>
  z
    .object({
      infer: z
        .string()
        .min(1, i18n.t("bud-ql-query-builder.save.form.name.errors.required"))

        // Restrict characters to allowed only
        .refine(
          (v) => {
            const transformed = inferSchema.safeParse({ infer: v });

            /*
             * Only return positive if the transformed infer is valid and has a resolved infer as
             * an input of something like '????' will result in ''
             */
            return (
              transformed.success &&
              transformed.data.infer.length &&
              transformed.data.infer !== defaultInsightName
            );
          },
          {
            message: i18n.t("bud-ql-query-builder.save.form.name.errors.specialCharacters"),
          },
        ),
      rules: z.array(
        rule.passthrough().refine(
          ({ operator, value, rule: ruleStr }) => {
            if (ruleStr === "amount" || ruleStr === "percentage_income") {
              // If the operator value is anything other than the default, ensure a valid value is entered
              if (
                operator !== "" &&
                operator !== undefined &&
                (value === "" || value === undefined || isNaN(Number(value)))
              ) {
                return false;
              }
            }

            return true;
          },
          {
            message: i18n.t("bud-ql-query-builder.query.form.transaction-value.errors.required"),
            path: ["value"],
          },
        ),
      ),
    })
    .passthrough();

export function useResolver(): Resolver<Query> {
  const i18n = useI18n();
  const schema = getValidationSchema(i18n);
  return zodResolver(schema);
}
