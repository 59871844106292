import { createSubscription } from "../api";

import type { CreateWebhookSchemaForm } from "./schema";
import type { Headers } from "./types";
import type { TargetEnvironment } from "../../../../../../types/environment";

type SelectedEvent = {
  callback: Promise<void>;
  title: string;
};

export const cleanOptionalSettings = (object: {
  [key: string]: string | undefined | { [key: string]: string };
}): { [key: string]: string } => {
  return Object.entries(object)
    .filter(([, value]) => Boolean(value))
    .reduce(
      (obj, [key, value]: [key: string, value: string]) => {
        obj[key] = value;
        return obj;
      },
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      {} as { [key: string]: string },
    );
};

export const formatHeaders = (headers: Headers[]): { [key: string]: string } => {
  return headers.reduce((object, { key, value }) => {
    if (!key || !value) return object;

    return {
      ...object,
      [key]: value,
    };
  }, {});
};

/**
 *  Create API calls based on the events selected by the user
 *
 * @param values - form values
 * @param currentEnvironment - current environment
 * @param clientId - clientId
 * @param subscriptions- subscriptions
 * @returns
 */
export const prepareWebhookApiCalls = async (
  values: CreateWebhookSchemaForm,
  { currentEnvironment, clientId }: { clientId: string; currentEnvironment: TargetEnvironment },
): Promise<SelectedEvent[]> => {
  const { webhookUrl, optionalSettings, webhooks } = values;
  const headers = optionalSettings?.headers ?? [];
  const formattedOptionalSettings = cleanOptionalSettings({
    ...optionalSettings,
    headers: formatHeaders(headers as Headers[]),
  });

  const selectedEvents = webhooks
    .filter(({ isEnabled }) => isEnabled)
    .map(({ name, category }) => {
      const callback = createSubscription({
        applicationId: clientId,
        category: category,
        currentEnvironment: currentEnvironment,
        eventType: name,
        optionalSettings: formattedOptionalSettings,
        webhookUrl: webhookUrl,
      });
      return { callback: callback, title: name };
    });

  return selectedEvents;
};

export const getIsValidBase64 = (value: string): boolean => {
  if (value === "") return false;

  try {
    return btoa(atob(value)) === value;
  } catch {
    return false;
  }
};

export const getIsValidPem = (value: string): boolean => {
  const parsedValue = atob(value);
  const pemHeaderFooter = /-----BEGIN .*-----|-----END .*-----/g;

  return Boolean(parsedValue.match(pemHeaderFooter)?.length);
};

/**
 * Validates the header entries by checking if both the key and value are either both empty or both not empty.
 * @param key - The key of the header entry.
 * @param value - The value of the header entry.
 * @returns false | 'key' | 'value' - Returns false if both key and value are either both empty or both not empty.
 * Returns 'key' if the key is empty and value is not empty.
 * Returns 'value' if the value is empty and key is not empty.
 */
export const validateHeaderEntries = ({
  key,
  value,
}: {
  key?: string;
  value?: string;
}): false | "key" | "value" => {
  const areBothValuesEmpty = !Boolean(key) && !Boolean(value);
  const areBothValuesNotEmpty = Boolean(key) && Boolean(value);

  if (areBothValuesEmpty || areBothValuesNotEmpty) {
    return false;
  }

  if (!Boolean(key)) {
    return "key";
  }

  return "value";
};
